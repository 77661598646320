import { InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { protectedResources } from '@src/authConfig'
import { AppContext, useAppStore } from '@src/contexts/AppContext'
import { signIn } from '@src/data/api/user-api/user-api'
import { isExpiredToken, parseJwt, saveUserToken, UserDto } from '@src/data/providersUtils/UserProviderUtils'

import { reportErrorToConsole } from '@src/services/error-logger'
import { useEffect } from 'react'

type Props = { children: React.ReactNode }

const UserProvider = ({ children }: Props) => {
  const { instance, accounts, inProgress } = useMsal()
  const appStore = useAppStore()
  useEffect(() => {
    if (accounts[0] && appStore.user === null && inProgress === InteractionStatus.None) {
      instance.setActiveAccount(accounts[0])
      instance
        .acquireTokenSilent({ account: accounts[0], scopes: protectedResources.getTokenAzure.scopes.read })
        .then((response) => {
          return signIn({ jwt: response.accessToken }).then(async (r) => {
            const icebergToken = parseJwt(r.jwt) as UserDto
            if (isExpiredToken(icebergToken.exp)) {
              await instance.loginRedirect()
            }
            saveUserToken(r.jwt)
            appStore.reloadUser()
          })
        })
        .catch(reportErrorToConsole)
    }
  })

  if (inProgress !== 'none' || (accounts.length && appStore.user === null)) return null
  return <AppContext.Provider value={appStore}>{children}</AppContext.Provider>
}

export default UserProvider
